var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[(_vm.localizedUrl !== '/zh_cn')?[_c('div',{staticClass:"h3 text-center -upper"},[_vm._v(_vm._s(_vm.$t('My Zenith')))]),_vm._v(" "),_c('div',{staticClass:"warrany-banner"},[_vm._v("\n      "+_vm._s(_vm.$t(
          'For all the watches purchased since January,1, 2022, we offer three additional year of warranty by registering your watch on our website.'
        ))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('form',{staticClass:"form col-12 col-md-5 col-lg-4 offset-lg-1",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('div',{staticClass:"h5"},[_vm._v(_vm._s(_vm.$t('I HAVE AN ACCOUNT')))]),_vm._v(" "),_c('base-input',{attrs:{"type":"email","id":"email","name":"email","placeholder":_vm.$t('Email') + ' *',"validations":[
            {
              condition: !_vm.$v.username.required && _vm.$v.username.$error,
              text: _vm.$t('Field is required.'),
            },
            {
              condition: !_vm.$v.username.email && _vm.$v.username.$error,
              text: _vm.$t('Please provide valid e-mail address.'),
            } ],"required":true},on:{"blur":function($event){return _vm.$v.username.$touch()}},model:{value:(_vm.username),callback:function ($$v) {_vm.username=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"username"}}),_vm._v(" "),_c('base-input',{attrs:{"type":"password","id":"password","name":"password","autocomplete":"current-password","placeholder":_vm.$t('Password') + ' *',"validations":[
            {
              condition: !_vm.$v.password.required && _vm.$v.password.$error,
              text: _vm.$t('Field is required.'),
            }
          ],"required":true},on:{"blur":function($event){return _vm.$v.password.$touch()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_vm._v(" "),_c('router-link',{staticClass:"link",attrs:{"to":_vm.localizedUrl + '/forgot-password'}},[_vm._v(_vm._s(_vm.$t('Forgot your password')))]),_vm._v(" "),_c('CusstomLoader',{attrs:{"is-loading":_vm.loading}},[_c('button',{staticClass:"btn -block",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t('Login')))])])],1),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-5 col-lg-4 offset-md-2"},[_c('div',{staticClass:"h5"},[_vm._v(_vm._s(_vm.$t('CREATE A NEW ACCOUNT')))]),_vm._v(" "),_c('div',{staticClass:"wysiwyg"},[_vm._v(_vm._s(_vm.$t('Create an account to benefit from our exclusive services and keep up to date with our latest publications.')))]),_vm._v(" "),_c('router-link',{staticClass:"btn -block",attrs:{"to":{ path: _vm.localizedUrl + '/account-creation', query: {'isNewLogin':'true', goToMyProfile: true} }}},[_vm._v(_vm._s(_vm.$t('Sign up')))])],1)])]:[_c('div',{staticClass:"row justify-content-center"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-3"},[_c('img',{attrs:{"src":require("../assets/img/QRcode.jpg"),"alt":_vm.$t('Create Your Account')}})])])]],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12"},[_c('div',{staticClass:"h3 text-center -upper"},[_vm._v("扫描二维码，关注真力时微信公众号。")])])}]

export { render, staticRenderFns }