<template>
  <div class="container">
    <template v-if="localizedUrl !== '/zh_cn'">
      <div class="h3 text-center -upper">{{ $t('My Zenith') }}</div>
      <div class="warrany-banner">
        {{
          $t(
            'For all the watches purchased since January,1, 2022, we offer three additional year of warranty by registering your watch on our website.'
          )
        }}
      </div>
      <div class="row">
        <form class="form col-12 col-md-5 col-lg-4 offset-lg-1" novalidate @submit.prevent="submit">
          <div class="h5">{{ $t('I HAVE AN ACCOUNT') }}</div>
          <base-input
            type="email"
            id="email"
            name="email"
            v-model.trim="username"
            @blur="$v.username.$touch()"
            :placeholder="$t('Email') + ' *'"
            :validations="[
              {
                condition: !$v.username.required && $v.username.$error,
                text: $t('Field is required.'),
              },
              {
                condition: !$v.username.email && $v.username.$error,
                text: $t('Please provide valid e-mail address.'),
              },
            ]"
            :required="true"
          />
          <base-input
            type="password"
            id="password"
            name="password"
            v-model="password"
            autocomplete="current-password"
            @blur="$v.password.$touch()"
            :placeholder="$t('Password') + ' *'"
            :validations="[
              {
                condition: !$v.password.required && $v.password.$error,
                text: $t('Field is required.'),
              }
            ]"
            :required="true"
          />
          <router-link :to="localizedUrl + '/forgot-password'" class="link">{{ $t('Forgot your password') }}</router-link>
          <CusstomLoader :is-loading="loading">
            <button type="submit" class="btn -block">{{ $t('Login') }}</button>
          </CusstomLoader>
        </form>
        <div class="col-12 col-md-5 col-lg-4 offset-md-2">
          <div class="h5">{{ $t('CREATE A NEW ACCOUNT') }}</div>
          <div class="wysiwyg">{{ $t('Create an account to benefit from our exclusive services and keep up to date with our latest publications.') }}</div>
          <router-link :to="{ path: localizedUrl + '/account-creation', query: {'isNewLogin':'true', goToMyProfile: true} }" class="btn -block">{{ $t('Sign up') }}</router-link>
        </div>
      </div>
    </template>
    <template v-else>
      <!-- China specific -->
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="h3 text-center -upper">扫描二维码，关注真力时微信公众号。</div>
        </div>
        <div class="col-12 col-md-3">
          <img src="../assets/img/QRcode.jpg" :alt="$t('Create Your Account')" />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import BaseInput from '../components/form/BaseInput'
import { required, email, minLength } from 'vuelidate/lib/validators'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { ReadyHook } from 'vue-asyncready'
import { metaLinkBuilder } from '../utilities'
import { addWarrantyByPan } from '../utilities/warranty'
import config from 'config'
import Vue from 'vue'
import CusstomLoader from '../components/utils/CustomLoader.vue'

export const registerFn = (cardNumber, caseNumber, router, route) => {
  return Vue.prototype.$db.usersCollection.getItem('current-user').then((user) => {
    const { custom_attributes, email, firstname, lastname } = user
    let payload
    if (custom_attributes) {
      const { value: country_id } = custom_attributes.find(({ attribute_code }) => attribute_code === 'country_id') || { value: 'XX' }
      const { value: telephone } = custom_attributes.find(({ attribute_code }) => attribute_code === 'phone') || { value: '0000000000' }
      payload = {
        warranty: {
          card_number: cardNumber,
          movement_number: '',
          case_number: caseNumber,
          country_id,
          telephone,
          email,
          firstname,
          lastname,
        },
      }
    } else {
      // if magento 1 account
      payload = {
        warranty: {
          card_number: cardNumber,
          movement_number: '',
          case_number: caseNumber,
          country_id: 'none',
          telephone: 'none',
          email,
          firstname,
          lastname,
        },
      }
    }
    return addWarrantyByPan(payload)
      .then(() => {
        router.push({ path: currentStoreView().url + '/warranty-extension-success', query: route.query })
      })
      .catch(() => {
        router.push({ path: currentStoreView().url + '/warranty-extension-error', query: route.query })
      })
  })
}
export const tryGetUserAndRegisterProduct = (cardNumber, caseNumber, router, route) => {
  return Vue.prototype.$db.usersCollection.getItem('current-user')
  .then((user) => {
    if (user) {
      return registerFn(cardNumber, caseNumber, router, route)
      .then((response) => {
        if(response && response.result && response.result.message) throw new Error(response.result.message)
        else router.push({ path: currentStoreView().url + '/warranty-extension-success', query: route.query})
      })
    }
  })
  .catch((error) => {
    router.push({ path:  currentStoreView().url + '/warranty-extension-error'})
  })
}

export default {
 metaInfo() {
    return { link: [
      ...metaLinkBuilder(this.$route).link,
        {
          name: 'description',
          vmid: 'description',
          content: this.$t('Log in to your Zenith customer account from this secure page.')
        },
      ],
      title: this.$t('Login'),
    }
  },
  name: 'Login',
  components: {
    BaseInput,
    CusstomLoader
  },
  mixins: [ReadyHook],
  computed: {
    localizedUrl() {
      return currentStoreView().url || ''
    },
    clientUrl() {
      return config.client.url
    },
  },
  methods: {
    ...mapActions('user', ['login', 'me']),
    ...mapActions('feedback', ['show']),
    isLoading(status) {
      this.loading = status
    },
    submit() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        setTimeout(() => {
          let bodyRect = document.body.getBoundingClientRect()
          let elem = document.querySelector('.-error')
          elem.querySelector('select, input').focus()
          let elemRect = elem.getBoundingClientRect()
          let offset = elemRect.top - bodyRect.top - 140
          window.scrollTo({
            top: offset,
            behavior: 'smooth',
          })
        }, 1)
      } else {
        this.isLoading(true)
        const { username, password } = this
        this.login({ username, password })
          .then((response) => {
            if (response.code === 401) {
              throw new Error(this.$t('Email and/or password was incorrect. Please try again.'))
            } else if (!('' + response.code).match(/2[0-9][0-9]/) && response.result && response.result.response) {
              if (this.localizedUrl === '/ja_jp') {
                throw new Error(this.$t('Loading. Please wait for a while.'))
              } else {
                const msg = JSON.parse(response.result.response.body)
                throw new Error(msg.message)
              }
            }
          })
          .then(() => {
            this.$gtm.trackEvent({
              event: 'accountLogin',
              siteLocation: 'Login page',
            })
            if (this.$route.query && this.$route.query.activateThirdYear) {
              // noop
            } else {
              const redirectTo = localStorage.getItem('redirectTo') ? localStorage.getItem('redirectTo') : this.localizedUrl + '/my-profile'
              this.$router.push({ path: redirectTo })
            }
          })
          .then(() => {
            const { activateThirdYear, cardNumber, caseNumber } = this.$route.query
            if (activateThirdYear && activateThirdYear === 'true') {
              return tryGetUserAndRegisterProduct(cardNumber, caseNumber, this.$router, this.$route)
            }
          })
          .catch((error) => {
            const message = error.message ? error.message : error
            this.show({ message: message, error, context: 'error' })
            this.isLoading(false)
          })
      }
    },
  },
  data() {
    return {
      loading: false,
      username: '',
      password: '',
    }
  },
  validations: {
    username: {
      required,
      email,
    },
    password: {
      required
    },
  },
  mounted() {
    this.$v.$reset()
    if(this.$route.query && this.$route.query.accountCreated) {
      this.show({ message: this.$t('Your account has been created successfully'), context: 'success' })
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~theme/css/core';

.container {
  margin-top: 8rem;
  margin-bottom: 10rem;
  color: var(--zenith-theme-primary);

  @include bp(max md) {
    .form {
      margin-bottom: 6rem;
    }
  }

  .h3 {
    margin-bottom: 6rem;
    @include bp(max md) {
      text-align: center;
      margin-bottom: 1rem;
    }
  }
  .h5 {
    margin-bottom: 4rem;
    @include bp(max md) {
      text-align: center;
      margin-bottom: 1rem;
    }
  }
  .link {
    color: var(--zenith-theme-primary);
    display: block;
    padding: 0;
    border: none;
    background: none;
    margin-top: 1rem;
    margin-bottom: 4rem;
    font-weight: 600;
    letter-spacing: em(1);
    outline: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .offset-md-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .wysiwyg {
      margin-bottom: auto;
      color: var(--zenith-component-text);
      @include bp(max md) {
        text-align: center;
        margin-bottom: 4rem;
      }
    }
  }
}

.warrany-banner {
  padding: 20px;
  margin-bottom: 6rem;
  text-align: center;
  @include bp(max md) {
    margin-bottom: 1rem;
  }
}

</style>
